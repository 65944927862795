<template>
  <div>
    <!-- Modal Absensi -->
    <loading v-if="Loading" />
    <!-- Tabel -->
    <b-row v-if="!Loading">
      <!-- Filter Tanggal -->
      <b-col md="3">
        <b-form-group label="Tanggal">
          <validation-provider #default="{ errors }" name="tanggal" rules="required">
            <flat-pickr @input="submitFilter" v-model="tanggal" :config="config" :class="errors.length === 0 ? 'form-control' : 'form-control is-invalid'" placeholder="Date Inserted" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <!-- Filter Nama -->
      <b-col md="3">
        <b-form-group label="Filter Name">
          <validation-provider #default="{ errors }" name="Name" rules="required">
            <multiselect @input="submitFilter" v-model="identifier" :options="dataPolisi" :multiple="false" :close-on-select="true" :clear-on-select="true" placeholder="Pilih USER" label="text" track-by="value" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="4" v-if="totalParfum">
        <b-form-group label=".">
          <b-button variant="success" @click="exportAbsen" v-if="validExport">Export Excel</b-button>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <!-- Tabel Absen -->
      <b-col md="8">
        <loading v-if="LoadingDetail" />
        <b-table v-if="!Loading && !LoadingDetail" striped hover :items="listRekap" :fields="rekapFields" :current-page="currentPage" :per-page="perPage">
          <template v-slot:cell(action)="row">
            <b-button v-if="row.item.tanggal == '2024-05-07'" @click="viewDetail(row.item)" variant="danger" size="sm">INVALID server sempet mati</b-button>&nbsp;
            <b-button v-else @click="viewDetail(row.item)" variant="primary" size="sm">Detail</b-button>&nbsp;
          </template>
        </b-table>
        <!-- Pagination -->
        <div class="d-flex justify-content-end">
          <b-pagination v-model="currentPage" :total-rows="listRekap.length" :per-page="perPage"></b-pagination>
        </div>
      </b-col>
      <b-col md="4" v-if="listAbsen.length>0">
        <loading v-if="LoadingDetail2" />
        <b-table v-if="!LoadingDetail2" striped hover :items="listAbsen" :fields="absenFields" :current-page="currentPageDetail" :per-page="perPageDetail" show-empty>
          <template v-slot:cell(total_waktu)="row">
            {{ row.item.total_waktu }} Menit
          </template>
        </b-table>
        <div class="d-flex justify-content-end">
          <b-pagination v-model="currentPageDetail" :total-rows="listAbsen.length" :per-page="perPageDetail"></b-pagination>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="dataUser.identifier">
      <!-- Tabel Absen -->
      <b-col md="8">
        <b-card>
          <b-card>
            <b-row>
              <b-col cols="4">
                <img :src="require('@/assets/images/avatars/8.png')" v-if="dataUser.imgurl == null && dataUser.sex == 'm'" style="min-width: 150px; min-height: 150px; max-width: 150px; max-height: 150px;">
                <img :src="require('@/assets/images/avatars/11.png')" v-if="dataUser.imgurl == null && dataUser.sex == 'f'" style="min-width: 150px; min-height: 150px; max-width: 150px; max-height: 150px;">
                <img :src="dataUser.imgurl" v-if="dataUser.imgurl" style="min-width: 200px; min-height: 200px; max-width: 200px; max-height: 200px;" class="img-fluid" alt="User Photo">
              </b-col>
              <b-col cols="4">
                <h3>Nama :</h3>
                <h5>- {{dataUser.firstname}} {{dataUser.lastname}}</h5>
                <h3>Total Parfum :</h3>
                <h5>- {{totalParfum}}</h5>
              </b-col>
              <b-col cols="4">
                <h3>Periode Absensi :</h3>
                <h5>- {{periode}}</h5>
                <h3>Total On Duty</h3>
                <h5>- Total Online: {{totalOn}}</h5>
              </b-col>
            </b-row>
          </b-card>
        </b-card>
      </b-col>
    </b-row>

    <!-- Tabel Rekap Absen -->
    <b-row v-if="listAbsen.length > 0">
      <!-- summary -->
    </b-row>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full";
import axios from "axios";
import Loading from '@/templates/Loading.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import _interfaces from '@/templates/_interfaces';
import Multiselect from 'vue-multiselect';
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Loading,
    ToastificationContent,
    Multiselect,
    axios,
    flatPickr
  },
  data() {
    return {
      Loading: false,
      LoadingDetail: false,
      LoadingDetail2: false,
      tanggal: null,
      identifier: null,
      totalParfum: null,
      dataPolisi: [],
      perPage: 10, // Jumlah item per halaman
      currentPage: 1, // Halaman saat ini
      perPageDetail: 10, // Jumlah item per halaman
      currentPageDetail: 1, // Halaman saat ini
      periode: null, // Halaman saat ini
      totalOn: null, // Halaman saat ini
      absenFields: [
        {key:"on_duty",text:"On Duty"},
        {key:"off_duty",text:"Off Duty"},
        {key:"total_waktu",text:"Total Waktu"}
      ],
      rekapFields: [
        {key:"tanggal_kerja",text:"Tanggal Kerja"},
        {key:"total_on_duty",text:"Total On Duty"},
        {key:"parfum",text:"Parfum"},
        {key:"action",text:"Action"}
      ],
      config: {
        mode: "range",
        altInput: true,
        dateFormat: "Y-m-d",
        altFormat: "d M Y",
        allowInput: true,
      },
      dataUser: [],
      listAbsen: [],
      listRekap: [],
      validExport: false,
    };
  },
  methods: {
    init(){
      this.getDataPolisi();
      this.checkValidAccessButton()
    },
    checkValidAccessButton(){
      axios.post("idp/master/role-config/auth",{id:6}).then((response) => {
        this.validExport = response.data.valid
      })
    },
    getDataPolisi(){
      this.Loading = true;
      axios.get("idp_dropdown/user-polisi").then((response) => {
        this.dataPolisi = response.data.data
      }).catch((error) => {
        if (error.status === 4000) {
          localStorage.clear();
          this.$router.push({ path: "/login" });
          ({
            title: "Login Expired",
            message: "Sesi Login Habis",
          });
        }
      }).finally(() => {
        this.Loading = false;
      });
    },
    submitFilter() {
      if(this.identifier.value != '' && this.tanggal != null){
        this.LoadingDetail = true;
        axios.post("idp/attendance/list",{identifier:this.identifier.value,tanggal:this.tanggal.split(" to ")}).then((response) => {
          this.totalParfum = response.data.parfum
          this.listRekap = response.data.rekap
          this.dataUser = response.data.user
          this.periode = response.data.periode
          this.totalOn = response.data.total_on
        }).catch((error) => {
          if (error.status === 4000) {
            localStorage.clear();
            this.$router.push({ path: "/login" });
            ({
              title: "Login Expired",
              message: "Sesi Login Habis",
            });
          }
        }).finally(() => {
          this.LoadingDetail = false;
          this.listAbsen = []
        });
      }
    },
    viewDetail(row){
      this.LoadingDetail2 = true
      axios.post("idp/attendance/detail",{identifier:row.identifier,tanggal:row.tanggal}).then((response) => {
        this.listAbsen = response.data.data
      }).catch((error) => {
        if (error.status === 4000) {
          localStorage.clear();
          this.$router.push({ path: "/login" });
          ({
            title: "Login Expired",
            message: "Sesi Login Habis",
          });
        }
      }).finally(() => {
        this.LoadingDetail2 = false;
      });
    },
    exportAbsen: function(){
      if (confirm("Are You Sure?")) {
        var split = this.tanggal.split('-');
        let param = { year: split[0],month:split[1] };
        this.loading = true;

        axios({
          url: "idp/export/absensi",
          data: param,
          method: "POST",
          responseType: "blob", //before "blob"
        })
          .then((response) => {
            var fileDownload = require("js-file-download");
            fileDownload(response.data, "rekap-absen-polisi.xlsx");
            for (const property in this.filters) {
              this.filters[property] = null;
            }
          })
          .catch((error) => {
            this.expired(error, "Error");
          })
          .finally(() => {
            this.loading = false;
          });
      }
    }
  },
  mounted(){
    this.init();
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>